@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 230, 230, 230;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

html {
  margin: 0;
}

body {
  box-sizing: border-box;
}

@layer utilities {
  .bg-diagonal-stripes {
    background-image: linear-gradient(
      45deg,
      rgba(255, 200, 200, 0.2) 25%,
      rgba(180, 80, 80, 0.2) 25%,
      rgba(180, 80, 80, 0.2) 50%,
      rgba(255, 200, 200, 0.2) 50%,
      rgba(255, 200, 200, 0.2) 75%,
      rgba(180, 80, 80, 0.2) 75%,
      rgba(180, 80, 80, 0.2) 100%
    );
    background-size: 20px 20px;
  }
}
